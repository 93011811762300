import {
  ContentfulCartProduct,
  ContentfulPurchasedProduct,
  ContentfulPurchasedPrintedDesign,
} from "@cms/types"
import { LineItem, CheckoutData } from "@shopify/types"
import {
  CartProducts,
  CartItem,
  LocalCheckout,
  PurchasedProducts,
} from "./types"
import { getIn } from "@utils/index"
import { getCustomerZipCode } from "@utils/api"
import * as R from "ramda"

export const graphQLRequest = async (fn, options) => {
  try {
    const { data, errors } = await fn(options)
    return { data, errors }
  } catch (error) {
    return { data: null, errors: error }
  }
}

export const UNKNOWN_LOCATION = "UNKNOWN_LOCATION"

export const fetchCustomerLocation = async () => {
  let data

  try {
    data = await getCustomerZipCode()
  } catch (error) {
    console.error(error)
  }

  const customerCountryCode = getIn(data, "country_code", UNKNOWN_LOCATION)
  const customerStateCode = getIn(data, "region_code", UNKNOWN_LOCATION)
  const customerZipCode = getIn(data, "postal", UNKNOWN_LOCATION)

  return {
    customerZipCode,
    customerStateCode,
    customerCountryCode,
  }
}

export const getMissingCartProductIds = (
  checkoutData: CheckoutData,
  cartProducts: CartProducts
) => {
  const lineItems = getIn(checkoutData, "lineItems.edges", []) as Array<{
    node: LineItem
  }>

  const ids: [Array<string>, Array<string>] = [[], []]

  lineItems.forEach(({ node: lineItem }) => {
    const sku = lineItem.variant.sku

    if (!cartProducts[sku]) {
      const contentfulVariantId = lineItem.customAttributes.find(
        ({ key, value }) =>
          key === "_contentfulVariantId" && value !== "bundledProduct"
      )

      if (contentfulVariantId) {
        ids[0].push(contentfulVariantId?.value)
      }
    }
  })

  return ids
}

// shopifyProductId = Encoded
// shopifyVariantId = gid://shopify/ProductVariant/someId
export const formatContentfulCartProductsData = (contentfulData) => {
  const products: Array<ContentfulCartProduct> = getIn(
    contentfulData,
    "productVariantCollection.items",
    []
  )

  return products.reduce((cartProducts, product) => {
    let multipleDevices
    const productDevices = getIn(
      product,
      "linkedFrom.productCollection.items.0.devices",
      []
    )
    if (productDevices.length > 0) {
      multipleDevices = productDevices.reduce((acc, curr) => {
        if (acc) {
          acc = acc + `, ${curr.name}`
        } else {
          acc = curr.name
        }

        return acc
      }, undefined)
    }

    const cartProduct = {
      contentfulProductId: getIn(
        product,
        "linkedFrom.productCollection.items.0.sys.id"
      ),
      contentfulVariantId: getIn(product, "sys.id"),
      shopifyProductId: getIn(
        product,
        "linkedFrom.productCollection.items.0.shopifyProduct.shopifyProductId"
      ),
      shopifyVariantId: getIn(product, "shopifyVariantId"),
      productSubType: getIn(
        product,
        "linkedFrom.productCollection.items.0.productSubType.slug"
      ),
      productSlug: getIn(product, "linkedFrom.productCollection.items.0.slug"),
      productName: getIn(
        product,
        "linkedFrom.productCollection.items.0.productName"
      ),
      variantName: getIn(product, "name"),
      device: multipleDevices
        ? multipleDevices
        : getIn(product, "linkedFrom.productCollection.items.0.device.name"),
      deviceType: getIn(
        product,
        "linkedFrom.productCollection.items.0.device.type.name"
      ),
      brand: getIn(product, "linkedFrom.productCollection.items.0.brand.name"),
      builtInMagnet: getIn(
        product,
        "linkedFrom.productCollection.items.0.builtInMagnet",
        false
      ),
      color: getIn(product, "color.name"),
      size: getIn(product, "size.size"),
      sku: getIn(product, "sku"),
      price: getIn(product, "price"),
      compareAtPrice: getIn(product, "compareAtPrice"),
      marketPrices: getIn(product, "marketPrices", {}),
      image: getIn(product, "imagesCollection.items.0.url"),
    }

    cartProducts[cartProduct.sku] = cartProduct
    return cartProducts
  }, {}) as CartProducts
}

export const getLocalCheckoutProps = (checkoutData: CheckoutData) => ({
  id: getIn(checkoutData, "id"),
  currencyCode: getIn(checkoutData, "subtotalPriceV2.currencyCode"),
  subtotal: getIn(checkoutData, "lineItemsSubtotalPrice.amount"),
  total: getIn(checkoutData, "subtotalPriceV2.amount"),
})

export const buildLocalCheckout = (
  checkoutData: CheckoutData,
  cartProducts: CartProducts,
  bundledProductSku?: string
) => {
  const lineItems = getIn(checkoutData, "lineItems.edges", []) as Array<{
    node: LineItem
  }>
  const localCheckoutItems = R.pipe(
    R.map(({ node: lineItem }: any) => {
      const sku = lineItem.variant.sku
      const cartProduct = cartProducts[sku]

      if (!cartProduct) {
        return undefined
      }

      return {
        ...cartProduct,
        quantity: lineItem.quantity,
      }
    }),
    R.filter(
      (item: any) => !!item || (!!item && item.sku !== bundledProductSku)
    )
  )(lineItems)

  return {
    ...getLocalCheckoutProps(checkoutData),
    items: localCheckoutItems,
  } as LocalCheckout
}

// cartItem.shopifyVariantId = gid://shopify/ProductVariant/someId
export const createCheckoutLineItem = (cartItem: CartItem) => {
  const customAttributes = [
    { key: "_contentfulProductId", value: cartItem.contentfulProductId },
    { key: "_contentfulVariantId", value: cartItem.contentfulVariantId },
    { key: "_productSlug", value: cartItem.productSlug },
  ]

  return {
    customAttributes,
    variantId: btoa(cartItem.shopifyVariantId),
    quantity: cartItem.quantity,
  }
}

// variantId = Encoded
export const getLineItemFromCheckoutData = (
  lineItems: Array<{ node: LineItem }>,
  variantId: string
) => {
  return lineItems.find((item: { node: LineItem }) => {
    return getIn(item, "node.variant.id") === variantId
  })
}

// variantId = Encoded
export const partitionLineItemsByVariantId = (
  lineItems: Array<{ node: LineItem }>,
  _variantId: string
) => {
  let variantId = _variantId

  if (variantId.indexOf("gid://shopify/ProductVariant") === -1) {
    try {
      variantId = atob(variantId)
    } catch (error) {
      variantId = variantId
    }
  }

  return R.pipe(
    R.map(({ node }: { node: LineItem }) => ({
      customAttributes: R.map((customAttribute) =>
        R.pick(["key", "value"], customAttribute)
      )(getIn(node, "customAttributes")) as any,
      variantId: getIn(node, "variant.id"),
      quantity: getIn(node, "quantity"),
    })),
    R.partition(({ variantId: _thisVariantId }) => {
      let thisVariantId = _thisVariantId

      if (thisVariantId.indexOf("gid://shopify/ProductVariant") === -1) {
        try {
          variantId = atob(thisVariantId)
        } catch (error) {
          variantId = thisVariantId
        }
      }
      return thisVariantId === variantId
    })
  )(lineItems)
}

export const buildPurchasedProducts = (
  data: Array<ContentfulPurchasedProduct | ContentfulPurchasedPrintedDesign>
) => {
  const purchasedProducts = data.reduce((acc, curr) => {
    if (curr.__typename === "ContentfulPrintedDesignVariant") {
      const combinedSku = `${getIn(
        curr,
        "linkedFrom.printedDesignCollection.items.0.baseSku",
        ""
      )} + ${getIn(curr, "sku", "")}`

      acc[combinedSku] = curr
    } else {
      acc[curr.sku] = curr
    }

    return acc
  }, {}) as PurchasedProducts
  return purchasedProducts
}
