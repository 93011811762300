import atob from "atob"
import * as R from "ramda"

export const isNilOrEmpty = (a: any): any => R.isNil(a) || R.isEmpty(a)

// Safely get nested prop
export const getIn = (o: object, path: string[] | string, defaultValue?: any) =>
  (o &&
    path &&
    R.view(R.lensPath(typeof path === "string" ? path.split(".") : path))(o)) ||
  defaultValue

const getDiscount = (compareAtPrice, price) => {
  if (compareAtPrice === price) {
    return -1
  }

  const discountPercentage = (compareAtPrice - price) / compareAtPrice
  return Math.floor(discountPercentage / 0.05)
}

export const formatContentfulVariant = (contentfulVariant) => ({
  ...R.pick([
    "sku",
    "upc",
    "price",
    "compareAtPrice",
    "marketPrices",
    "name",
    "language",
    "specs",
    "outOfStockMessage",
    "resourcesText",
    "resourcesMedia",
    "showProp65Warning",
  ])(contentfulVariant),
  contentfulVariantId: getIn(contentfulVariant, "contentful_id"),
  variantId: getIn(contentfulVariant, "shopifyVariantId"),
  deviceSlug: getIn(contentfulVariant, "device.slug"),
  deviceName: getIn(contentfulVariant, "device.name"),
  size: getIn(contentfulVariant, "size.size"),
  color: getIn(contentfulVariant, "color.name"),
  hexString: getIn(contentfulVariant, "color.hexString"),
  swatchImage: getIn(contentfulVariant, "color.swatchImage.file.url"),
  images: getIn(contentfulVariant, "images", []).map(
    (image) => image?.file?.url
  ),
  discount: getIn(contentfulVariant, "compareAtPrice")
    ? getDiscount(
        getIn(contentfulVariant, "compareAtPrice"),
        getIn(contentfulVariant, "price")
      )
    : -1,
})

export const getDecodedShopifyProductId = (productId) => {
  let decodedProductId
  try {
    decodedProductId = atob(productId)
  } catch (error) {
    decodedProductId = productId
  }

  return parseInt(R.replace("gid://shopify/Product/", "")(decodedProductId))
}

export const getDecodedShopifyProductIdString = (productId: string) => {
  let decodedProductId
  try {
    decodedProductId = atob(productId)
  } catch (error) {
    decodedProductId = productId
  }

  return R.replace("gid://shopify/Product/", "")(decodedProductId)
}

export const getDecodedShopifyVariantIdString = (variantId: string) => {
  let decodedVariantId
  try {
    decodedVariantId = atob(variantId)
  } catch (error) {
    decodedVariantId = variantId
  }

  return R.replace("gid://shopify/ProductVariant/", "")(decodedVariantId)
}

const NOT_CORRECTLY_ENCODED_ERROR =
  "Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded."
export const getDecodedShopifyCustomerId = (customerId) => {
  let decodedCustomerId

  try {
    decodedCustomerId = atob(customerId)
  } catch (error) {
    if (error.message === NOT_CORRECTLY_ENCODED_ERROR) {
      decodedCustomerId = customerId
    } else {
      decodedCustomerId = ""
    }
  }

  return R.replace("gid://shopify/Customer/", "")(decodedCustomerId)
}

export const getCollectionBannerData = (data) => {
  return {
    collectionBannerType: data?.collectionBannerType,
    bannerImage: data?.bannerImage?.file?.url || "",
    mobileBannerImage:
      data?.mobileBannerImage?.file?.url || data?.bannerImage?.file?.url || "",
    bannerHeader: data?.bannerHeader,
    bannerSubheader: data?.bannerSubheader?.bannerSubheader,
    paragraphHeader: data?.paragraphHeader,
    paragraphBody: data?.paragraphBody?.paragraphBody,
    paragraphAsymmetricalSplit: !!data?.paragraphAsymmetricalSplit,
  }
}

export const pluralizeDeviceType = (deviceTypeLabel) => {
  if (!deviceTypeLabel) {
    return ""
  }

  const lastTwoLetters = deviceTypeLabel.slice(deviceTypeLabel.length - 2)
  const lastLetter = deviceTypeLabel[deviceTypeLabel.length - 1]

  if (lastTwoLetters === "ch") {
    return `${deviceTypeLabel}es`
  } else if (lastLetter === "s") {
    return deviceTypeLabel
  } else {
    return `${deviceTypeLabel}s`
  }
}
