export const UTM_PARAMS_KEY = "uagUTMParamsKey"
export const VISITOR_INFO_KEY = "uagVisitorInfoKey"
export const ELEVAR_COOKIES_KEY = "uagElevarCookiesKey"
export const CUSTOMER_KEY = "uagCustomerKey"
export const CHECKOUT_KEY = "uagCheckoutKey"
export const DISCOUNT_KEY = "uagDiscountKey"
export const CUSTOMER_ZIP_CODE_KEY = "uagCustomerZipCodeKey"
export const CUSTOMER_STATE_CODE_KEY = "uagCustomerStateCodeKey"
export const CUSTOMER_COUNTRY_CODE_KEY = "uagCustomerCountryCodeKey"
export const RECENTLY_VIEWED_PRODUCTS_KEY = "uagRecentlyViewedProductsKey"
export const SELECTED_PRODUCTS_KEY = "uagSelectedProductsKey"
