import { createModel } from "@rematch/core"
import { RootModel } from "./index"
import DefaultClient from "apollo-boost"
import createContentfulClient from "@cms/createApolloClient"
import { GetDiscountedProductsData } from "@cms/graphql/queries"
import { AutomaticDiscountData, DiscountedProductsData } from "../types"
import { graphQLRequest } from "../utils"
import { getInventoryByLocation, getAutomaticDiscounts } from "@utils/api"
import { formatInventoryData } from "@hooks/useMultiWarehouseInventory"
import _ from "lodash-es"

export interface DiscountsState {
  automaticDiscountsFetched: boolean
  automaticDiscounts: AutomaticDiscountData[]
  discountedProductsData: DiscountedProductsData
  discountedProductsInventoryData: any
}

const discounts = createModel<RootModel>()({
  name: "discounts",
  state: {
    automaticDiscountsFetched: false,
    automaticDiscounts: [] as AutomaticDiscountData[],
    discountedProductsData: {} as DiscountedProductsData,
    discountedProductsInventoryData: {} as any,
  } as DiscountsState,
  // https://github.com/rematch/rematch/issues/816
  // @ts-ignore
  selectors: (slice) => ({}),
  reducers: {
    setAutomaticDiscountsFetched(
      state: DiscountsState,
      automaticDiscountsFetched: boolean
    ) {
      return {
        ...state,
        automaticDiscountsFetched,
      }
    },

    setAutomaticDiscounts(
      state: DiscountsState,
      automaticDiscounts: AutomaticDiscountData[]
    ) {
      return {
        ...state,
        automaticDiscounts,
      }
    },

    setDiscountedProductData(
      state: DiscountsState,
      discountedProductsData: DiscountedProductsData
    ) {
      return {
        ...state,
        discountedProductsData,
      }
    },

    setDiscountedProductInventoryData(
      state: DiscountsState,
      discountedProductsInventoryData: any
    ) {
      return {
        ...state,
        discountedProductsInventoryData,
      }
    },
  },
  effects: (dispatch: any) => ({
    async fetchAutomaticDiscounts(_, rootState) {
      const automaticDiscountsData = await getAutomaticDiscounts()
      dispatch.discounts.setAutomaticDiscounts(automaticDiscountsData || [])
      dispatch.discounts.setAutomaticDiscountsFetched(true)

      const contentfulProductIds: any = []

      if (automaticDiscountsData?.length > 0) {
        for (let i = 0; i < automaticDiscountsData.length; i++) {
          const { customerGetsProducts, customerBuysProducts, idMap } =
            automaticDiscountsData[i]

          if (customerGetsProducts?.length && idMap) {
            customerGetsProducts.forEach((shopifyId) => {
              contentfulProductIds.push(idMap[shopifyId])
            })
          }

          if (customerBuysProducts?.length && idMap) {
            customerBuysProducts.forEach((shopifyId) => {
              contentfulProductIds.push(idMap[shopifyId])
            })
          }
        }

        const filteredContentfulProductIds = contentfulProductIds.filter(
          (id) => !!id
        )

        if (filteredContentfulProductIds.length) {
          dispatch.discounts.fetchDiscountedProducts(
            filteredContentfulProductIds
          )
        }
      }
    },

    async fetchDiscountedProducts(ids, rootState) {
      const client: DefaultClient<any> = createContentfulClient()
      const batches = _.chunk(ids, 100)
      const batchItems: any = []

      for (let i = 0; i < batches.length; i++) {
        const { data, errors } = await graphQLRequest(client.query, {
          query: GetDiscountedProductsData,
          variables: { ids: batches[i] },
        })

        if (data?.productCollection?.items) {
          batchItems.push(data?.productCollection?.items)
        }
      }

      if (batchItems.length > 0) {
        const items: any = _.flatten(batchItems)
        const formattedData = items.reduce((productMap, product) => {
          productMap[product.sys.id] = {
            contentful_id: product.sys.id,
            customPrint: product.customPrint,
            slug: product.slug,
            brand: product.brand,
            device: product.device,
            devices: null,
            builtInMagnet: product.builtInMagnet,
            shopifyProduct: product.shopifyProduct,
            productName: product.productName,
            productSubType: product.productSubType,
            variants: product.variantsCollection.items.map((v) => ({
              contentful_id: v.sys.id,
              shopifyVariantId: v.shopifyVariantId,
              device: v.device,
              sku: v.sku,
              price: v.price,
              compareAtPrice: v.compareAtPrice,
              marketPrices: v.marketPrices,
              name: v.name,
              language: v.language,
              size: v.size,
              color: {
                name: v.color.name,
                hexString: v.color.hexString,
                swatchImage: v.color.swatchImage
                  ? {
                      file: {
                        url: v.color.swatchImage?.url?.replace("https:", ""),
                      },
                    }
                  : null,
              },
              images: [
                {
                  file: {
                    url: v.imagesCollection.items[0]?.url?.replace(
                      "https:",
                      ""
                    ),
                  },
                },
              ],
              outOfStockMessage: v.outOfStockMessage,
            })),
          }

          return productMap
        }, {})

        if (Object.values(formattedData).length) {
          const variantSkus = _.flatten(
            Object.values(formattedData).map(({ variants }) =>
              variants.map(({ sku }) => sku)
            )
          )

          const inventoryData = await getInventoryByLocation(variantSkus)
          const formattedInventoryData = inventoryData
            ? formatInventoryData(inventoryData)
            : {}

          dispatch.discounts.setDiscountedProductInventoryData(
            formattedInventoryData
          )
        }

        dispatch.discounts.setDiscountedProductData(formattedData)
      }
    },
  }),
})

export default discounts
