import gql from "graphql-tag"

export const GetCheckoutCartItemProductData = gql`
  query GetCheckoutCartItemProductData($ids: [String]!) {
    productVariantCollection(where: { sys: { id_in: $ids } }) {
      items {
        __typename
        sys {
          id
        }
        shopifyVariantId
        sku
        name
        price
        compareAtPrice
        marketPrices
        color {
          name
        }
        size {
          size
        }
        imagesCollection(limit: 1) {
          items {
            url
          }
        }
        linkedFrom {
          productCollection(limit: 1) {
            items {
              sys {
                id
              }
              slug
              productName
              productSubType {
                slug
              }
              shopifyProduct
              device {
                name
                type {
                  name
                }
              }
              brand {
                name
              }
              builtInMagnet
            }
          }
        }
      }
    }
  }
`

export const GetCheckoutCartItemPrintedDesignData = gql`
  query GetCheckoutCartItemPrintedDesignData($ids: [String]!) {
    printedDesignVariantCollection(where: { sys: { id_in: $ids } }) {
      items {
        __typename
        sys {
          id
        }
        device {
          name
          type {
            name
          }
          brand {
            name
          }
        }
        sku
        name
        price
        compareAtPrice
        marketPrices
        color {
          name
        }
        imagesCollection(limit: 1) {
          items {
            url
          }
        }
        linkedFrom {
          printedDesignCollection(limit: 1) {
            items {
              sys {
                id
              }
              baseSku
              slug
              productName
              shopifyProduct
              shopifyVariantId
            }
          }
        }
      }
    }
  }
`

export const GetDiscountedProductsData = gql`
  query GetDiscountedProductsData($ids: [String]!) {
    productCollection(where: { sys: { id_in: $ids } }) {
      items {
        sys {
          id
        }
        customPrint
        slug
        brand {
          slug
          name
        }
        device {
          slug
          name
          type {
            name
          }
        }
        builtInMagnet
        shopifyProduct
        productName
        productSubType {
          slug
        }
        variantsCollection(
          limit: 10
          where: { sys: { publishedAt_exists: true } }
        ) {
          items {
            sys {
              id
            }
            shopifyVariantId
            device {
              slug
              name
            }
            sku
            price
            marketPrices
            compareAtPrice
            name
            language
            size {
              size
            }
            color {
              name
              hexString
              swatchImage {
                url
              }
            }
            imagesCollection(limit: 1) {
              items {
                url
              }
            }
            outOfStockMessage
          }
        }
      }
    }
  }
`

export const GetCustomerPurchasedProductsData = gql`
  query GetCustomerPurchasedProductsData($ids: [String]!) {
    productVariantCollection(where: { sys: { id_in: $ids } }) {
      items {
        __typename
        sys {
          id
        }
        device {
          slug
          name
        }
        sku
        name
        imagesCollection(limit: 1) {
          items {
            url
          }
        }
        linkedFrom {
          productCollection(limit: 1) {
            items {
              sys {
                id
              }
              customPrint
              slug
              productName
              brand {
                slug
                name
              }
              device {
                slug
                name
                type {
                  slug
                  name
                }
              }
              series {
                slug
                name
              }
              productSubType {
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`
